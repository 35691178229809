<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯中心" />
    </div> -->
    <!-- tab栏 -->
    <van-sticky>
      <van-search
        value="请选择筛查条件"
        @click="searchClick"
        shape="round"
        background="#ffffff"
        disabled
      >
      </van-search>
      <van-popup v-model="searchShow" position="top" round>
        <div>
          <van-field
            v-model="listfrom.name"
            placeholder="请输入旅客姓名"
            label="旅客姓名"
            name="name"
          />
          <van-field
            v-model="listfrom.typeName"
            readonly
            label="房间类型"
            placeholder="请选择房间类型"
            @click="typeShow = true"
          />
          <van-popup v-model="typeShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="typeList"
              value-key="Name"
              @confirm="onTypeFinish"
              @cancel="typeShow = false"
            />
          </van-popup>
        </div>
        <div
          style="
            display: flex;
            margin-bottom: 10px;
            justify-content: space-around;
          "
        >
          <button
            @click="resetSubmit"
            class="buttonStyle"
            style="background-color: #ff976a"
          >
            重 置
          </button>
          <button @click="filtersSubmit" class="buttonStyle">搜 索</button>
        </div>
      </van-popup>
    </van-sticky>
    <div class="information">
      <van-tabs v-model="riKind" @click="onClick" color="#1989fa">
        <van-tab title="待确认" name="1"> </van-tab>
        <van-tab title="待入住" name="2"> </van-tab>
        <van-tab title="已入住" name="3"> </van-tab>
        <van-tab title="已退订" name="8"> </van-tab>
        <van-tab title="已取消" name="9"> </van-tab>
      </van-tabs>
      <div v-for="item in list" :key="item.index">
        <div class="list">
          <div @click="preHotel(item)">
            <div class="title">{{ item.OrgName }}</div>
            <div class="flex">
              <span>旅客姓名: {{ item.Name }}</span>
              <span v-if="item.RoomNum != ''"
                >房间数量: {{ item.RoomNum }}</span
              >
            </div>
            <div class="flex">
              <span>身份证号: {{ item.IdCard }}</span>
            </div>
            <div class="flex">
              <span>手机号: {{ item.Mobile }}</span>
            </div>
            <div
              v-if="item.Post != null && item.Post != ''"
              style="padding: 10px 20px"
            >
              预订时间: {{ parseTime(item.PreTime) }}
            </div>

            <div class="flex">
              <span>预计到店时间: {{ parseTime(item.ArriveTime) }}</span>
            </div>

            <div class="flex" v-if="item.Status != 9">
              <span>预计入住日期: {{ parseTime(item.InDate) }}</span>
            </div>
            <div class="flex" v-if="item.Status != 9">
              <span>预计退房日期: {{ parseTime(item.LeaveDate) }}</span>
            </div>
            <div class="flex" v-if="item.Status > 2 && item.Status != 9">
              <span>确认时间: {{ parseTime(item.ConfirmTime) }}</span>
            </div>
            <div class="flex" v-if="item.Status > 2 && item.Status != 9">
              <span>确认人: {{ item.ConfirmMan }}</span>
            </div>
            <div class="flex" v-if="item.Situation != ''">
              <span>情况说明: {{ item.Situation }}</span>
            </div>

            <div class="flex" v-if="item.Status > 2 && item.Status != 9">
              <span>入住时间: {{ item.InTime }}</span>
            </div>
            <div class="flex" v-if="item.Status == 9">
              <span>取消时间: {{ item.CancelTime }}</span>
            </div>

            <div class="Status" style="color: #02c08f">{{ item.TypeName }}</div>
          </div>
          <div
            style="
              display: inline-block;
              width: 100%;
              text-align: right;
              margin-bottom: 5px;
            "
            class="ContentB"
          >
            <span class="centertime" style="color: #333">
              <van-button
                type="warning"
                v-if="item.Status == 1"
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                @click="inDateClick(item)"
                size="small"
                >确认入住</van-button
              >
              <van-button
                type="info"
                v-if="item.Status == 2"
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                @click="updata(item)"
                size="small"
                >入 住</van-button
              >
              <!-- <van-button type="warning"
                v-if="item.Status == 1"
                style="width: 80px; font-size: 13px;border-radius: 5px;margin-right: 5px;"
                @click="leaveDateClick(item)"
                size="small">离职</van-button> -->
              <!-- <van-button type="danger"
                v-if="item.Status == 1"
                style="width: 80px; font-size: 13px;border-radius: 5px;margin-right: 5px;"
                @click="dangerClick(item)"
                size="small">删除</van-button> -->
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="center"
          v-for="item in list"
          :key="item.index">
          <div class="Content"
            style="padding: 8px 10px; line-height: 30px">
            <div>
              <div style="font-size: 18px; color: #333;display: flex;justify-content: space-between;">
                <div style="padding-right:10px;">
                  <van-icon name="manager" /> {{ item.Name }}</div>
                <div>
                  <van-icon name="phone" /> {{ item.Mobile }}</div>
              </div>

              <div class="centertime"
                style="font-size: 18px; color: #333">
                身份证号：{{ item.IdCard }}
              </div>
              <div class="centertime"
                style="font-size: 18px; color: #333">
                岗位：{{ item.Post }} </div>
              <div class="centertime"
                style="font-size: 18px; color: #333">
                入职日期：{{ item.InDate }}
              </div>
              <div class="centertime"
                style="font-size: 18px; color: #333">
                最后登录时间：{{ item.LoginTime }} </div>
              <div class="centertime"
                style="font-size: 18px; color: #333">
                登录次数：{{ item.LoginCount }}
              </div>
              <div class="centertime"
                v-if="item.Status == 2"
                style="font-size: 18px; color: #333">
                离职日期：{{ item.LeaveDate }}
              </div>
            </div>
            
          </div>
        </div> -->

      <!-- <div style="position: fixed;bottom: 15px;width:100%;">
        <div style="width:100%; text-align: center;">
          <van-button class="button"
            @click="postfrom(1)"
            style="width:95%;border-radius: 5px;"
            type="info">新增</van-button>
        </div>
      </div> -->
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
    <van-dialog
      v-model="preHotelShow"
      title="确认入住"
      show-cancel-button
      @confirm="inDateQr"
    >
      <div class="filtersBox">
        <div class="labelClass" style="padding: 10px 6px">
          <div>确认结果</div>
        </div>
        <div style="padding: 10px 14px 6px 5px">
          <van-radio-group v-model="datafromCon.Result" direction="horizontal">
            <van-radio name="1" class="radio">确认预订</van-radio>
            <van-radio name="2" class="radio">退订</van-radio>
          </van-radio-group>
        </div>
      </div>
      <van-cell-group>
        <van-field
          v-model="datafromCon.Situation"
          v-if="datafromCon.Result == 2"
          label="情况说明"
          placeholder="请输入情况说明"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script>
import {
  HConfirmPreHotel,
  HSetLiveStatus,
  HDeleteHotelStaff,
} from "@/api/RealInfo";
import { HGetPreHotelPage, HGetMyRoomTypeList } from "@/api/shop";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from "vant";
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      riKind: 0, //tab栏
      list: {},
      listfrom: {
        page: 0,
        limit: 10,
        typeId: "",
        typeName: "",
        status: 1,
      }, //查询传参
      cascaderValue: "",
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      Status: false,
      jiezhongTime: false,
      searchShow: false,
      typeShow: false,
      typeList: [],
      minDate: new Date(2018, 0, 1),
      maxDate: new Date(2030, 10, 1),
      dataform: {},
      jiezhognDate: "",
      yimiaolist: [
        { id: "1", Name: "未接种" },
        { id: "2", Name: "第一针" },
        { id: "3", Name: "第二针" },
        { id: "4", Name: "第三针" },
      ],
      datafrom: {},
      hslist: {},
      preHotelShow: false,
      datafromCon: {
        HotelId: "",
        PreId: "",
        Result: "",
        Situation: "",
      },
    };
  },
  filters: {
    capitalizeTime: function (value) {
      if (!value) return "";
      let gstime = value.substring(0, 10);
      return gstime;
    },
  },
  methods: {
    // 获取房间类型
    getMyRoomTypeList() {
      HGetMyRoomTypeList({
        openID: getOpenId(),
        hotelId: this.$route.params.hotelId,
        limit: 99,
      }).then((res) => {
        if (res.data.code == 0) {
          this.typeList = res.data.data;
        }
      });
    },
    // 选中房间类型
    onTypeFinish(val) {
      this.listfrom.typeId = val.TypeId;
      this.listfrom.typeName = val.Name;
      this.typeShow = false;
    },
    // 重置
    resetSubmit() {
      this.listfrom.page = 1;
      this.listfrom.limit = 10;
      this.listfrom.name = "";
      this.listfrom.typeId = "";
      this.listfrom.typeName = "";
    },
    // 搜索
    filtersSubmit() {
      this.listfrom.page = 1;
      this.list = [];
      this.searchShow = false;
      this.getList();
    },
    // 点击查看更多条件
    searchClick() {
      this.searchShow = true;
    },
    // 获取入住订单列表
    getList() {
      this.listfrom.openID = getOpenId();
      this.listfrom.hotelId = this.$route.params.hotelId;
      HGetPreHotelPage(this.listfrom).then((res) => {
        if (res.data.code == 0) {
          if (res.data.count == 0) {
            this.finished = true;
            this.list = {};
          } else {
            this.list = res.data.data;
            this.total = res.data.count;
            this.finished = true;
          }
        }
      });
    },
    // 新增
    postfrom() {
      var url =
        "/hotels/myHotelsList/myStaffList/addStaff/0/" +
        this.$route.params.hotelId +
        "/0";
      this.$router.push(url);
    },

    // 详情
    preHotel(val) {
      var url =
        "/hotels/myHotelsXq/hotelOrder/hotelOrderXq/" +
        val.HotelId +
        "/" +
        val.PreId;
      this.$router.push(url);
    },
    // 点击确认入住
    inDateClick: function (val) {
      this.preHotelShow = true;
      this.datafromCon = {
        HotelId: this.$route.params.hotelId,
        PreId: val.PreId,
        Result: "",
        Situation: "",
        OpenID: getOpenId(),
      };
    },
    inDateQr() {
      if (this.datafromCon.Result == "") {
        Toast.fail("请选择确认结果");
        return false;
      }
      if (this.datafromCon.Result == "2" && this.datafromCon.Situation == "") {
        Toast.fail("请输入情况说明");
        return false;
      }
      console.log(this.datafromCon);
      HConfirmPreHotel(this.datafromCon)
        .then((res) => {
          if (res.data.code == 0) {
            Toast.success("操作成功");
            this.getList();
          } else {
            Toast.fail(res.data.msg);
          }
        })
        .catch(() => {});
    },
    // 入住
    updata: function (val) {
      Dialog.confirm({
        title: "提示",
        message: "确定要入住吗",
      })
        .then(() => {
          console.log("确认");
          HSetLiveStatus({
            HotelId: val.HotelId,
            PreId: val.PreId,
            OpenID: getOpenId(),
          })
            .then((res) => {
              if (res.data.code == 0) {
                Toast.success("操作成功");
                this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },
    // 删除
    dangerClick: function (val) {
      Dialog.confirm({
        title: "提示",
        message: "确定要删除该员工吗",
      })
        .then(() => {
          console.log("确认");
          HDeleteHotelStaff({
            HotelId: this.$route.params.hotelId,
            StaffId: val.StaffNum,
            OpenID: getOpenId(),
          })
            .then((res) => {
              if (res.data.code == 0) {
                Toast.success("操作成功");
                this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },
    // 疫苗选择器
    onStatus(val) {
      this.datafrom.Status = val.id;
      this.datafrom.StatusDesc = val.Name;
      this.datafrom.VaccinateTime = "";
      this.Status = false;
    },
    onjiezhongFend(val) {
      //接种时间 确认
      this.datafrom.VaccinateTime = this.dateformat(val);
      this.jiezhongTime = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    // 下拉刷新
    onLoad() {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick(e) {
      this.list = {};
      this.listfrom.status = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.getList();
    });
    this.getMyRoomTypeList();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.van-tabs__content {
  border-top: 5px solid #fff;
}
.van-tabs__wrap--scrollable {
  margin-bottom: 5px;
}

.list {
  position: relative;
  width: 96%;
  margin: 2%;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
  box-shadow: 0 0 5px #dadada;
}

.list .title {
  padding: 10px 20px 5px;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 14px;
  color: #333;
  padding: 10px 20px 5px;
  justify-content: space-between;
}

.list .flex text:nth-child(1) {
  width: 40%;
}

.list .flex text:nth-child(2) {
  width: 60%;
}

.list .Status {
  position: absolute;
  top: 0px;
  right: 10px;
  /* background: #4b8cff; */
  font-size: 16px;
  padding: 5px;
  color: #fff;
  /* width: 70px; */
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.filtersBox {
  display: flex;
  /* border-bottom: 1px solid #ebedf085; */
  margin: 10px;
}
.labelClass {
  font-size: 14px;
  font-weight: 500;
  width: 86px;
  color: #646566 !important;
}
.radio {
  font-size: 14px;
  color: #646566 !important;
}
.buttonStyle {
  width: 45%;
  border-radius: 8px;
  background: #1989fa;
  color: white;
  height: 35px;
  line-height: 35px;
  border: 0px solid;
}
</style>